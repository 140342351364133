footer.main {
  padding: 1.5em 2em;
  scroll-snap-align: start;
//   display: flex;

//   div {
//       flex-basis: calc(50% - 1em);
//       justify-content: space-between;
//       flex-grow:0;
//       flex-shrink:0;
//   }
}
