@import "../../../styles/vars.scss";

section.scene {
  width: 100%;

  &.then {
    background: $black;
    color: $white;
  }

  &.onwards {
    & > div {
      flex-direction: row-reverse;
    }
  }

  .text-content {
    flex-basis: calc(50% - 1em);
    flex-grow: 0;
    margin-top: 4em;
    align-self: stretch;

    h2 {
      font-size: $large;
      margin-bottom: 2em;
      margin-top: 1em;
    }

    @media screen and (max-width: $mobile-breakpoint) {
      margin-top: 8em;
      align-self: flex-start;
      width: 100%;
    }

  }

  .artwork-wrapper {
    margin-top: -1.5em;
    width: calc(50% - 1em);
    margin-bottom: 6em;
    @media screen and (max-width: $mobile-breakpoint) {
      width: calc(100% + 4em);
      margin-left: -2em;
    }
  }
}
