@import "./reset.scss";
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;700&family=Space+Mono&display=swap");
@import "./vars.scss";

html,
body {
  // overflow-y:hidden;
  // height:100vh;
}

body {
  font-family: $monospace;
  margin: 0;
  font-size: 18px;
  color: $black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $white;

  @media screen and (max-width: $tablet-breakpoint) {
    font-size: 16px;
  }
  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 4vw;
  }
}

.wrapper {
  width: 100%;
  max-width: 2000px;
  margin: 0 auto;
  // max-height:100vh;

  &.flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media screen and (max-width: $mobile-breakpoint) {
      flex-direction: column-reverse!important;
    }
  }

  &.header-flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    
  }

}

h1,
h2 {
  font-family: $sans-serif;
}

h1 {
  font-size: $medium;
}

section {
  padding: 1.5em 2em 4em;
  // min-height: 100vh;
  // scroll-snap-align: start;

  @media screen and (max-width: $mobile-breakpoint) {
  padding-bottom: 6em;
  }
}

a {
  text-decoration: none;
  color: inherit;
  font-weight: bold;
}
