@import "../../../styles/vars.scss";

header.main {
  padding: 1.5em 2em;
  width: 100%;
  position: absolute;

  div {
    flex-basis: calc(50% - 1em);
    flex-grow: 0;
    flex-shrink: 0;

    @media screen and (max-width: $mobile-breakpoint) {
      flex-basis: auto;
    }
  }

  .title {
    // h1 {
    //   font-weight: normal;
    // }
  }
  
}
