// fonts
$monospace: "Space Mono", monospace;
$sans-serif: "Space Grotesk", sans-serif;

// font sizes
$small: 1em;
$medium: 2em;
$large: 4em;

// grid
$col-1: 25%;
$col-2: 50%;
$col-3: 75%;
$col-4: 100%;

// colors
$white: #f1f5f0;
$black: #222323;

// breakpoints
$tablet-breakpoint: 90rem;
$mobile-breakpoint: 50rem;
